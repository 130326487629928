<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
         <el-form-item  label="名称">
           <el-input v-model="title"></el-input>
        </el-form-item>
        <el-form-item label="教学类目">
          <el-select
            v-model="categories"
            clearable
            name="categories_child"
            remote
            style="width: 150px"
          >
            <el-option
              v-for="item in listPageChild"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="status" placeholder="请选择" style="width: 100px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布来源">
          <el-select
            v-model="create_type"
            placeholder="请选择"
            style="width: 140px"
          >
            <el-option label="海马后台(官方)" value="1"> </el-option>
            <el-option label="海马教练局" value="2"> </el-option>
            <el-option label="海马运动用户端" value="3"> </el-option>
            <el-option label="公会" value="4"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市">
          <citySelect
            :area="true"
            @change="cityChange"
            :checkStrictly="true"
          ></citySelect>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
        <el-button type="warning" @click="addCourse">发布需求</el-button>
      </el-form>
      <!-- 列表 -->
      <el-table
        :data="DataList"
        :fit="true"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <el-table-column type="index"></el-table-column>
        <el-table-column
          v-if="create_type == 2"
          label="教练名称"
          prop="coach_name"
        ></el-table-column>
        <el-table-column label="课程名称" prop="title"></el-table-column>
        <el-table-column label="课程类目" prop="categories"></el-table-column>
        <el-table-column label="总课节数" prop="course_num"></el-table-column>
        <el-table-column label="服务方式" prop="service_type">
          <template slot-scope="{ row }">
            <p v-if="row.service_type == 1">海马场地</p>
            <p v-if="row.service_type == 2">上门教学</p>
            <p v-if="row.service_type == 3">自有场地</p>
             <p v-if="row.service_type == 4">陪练</p>
          </template>
        </el-table-column>
        <el-table-column label="课程预算" prop="amount_range"></el-table-column>
        <el-table-column label="城市" prop="city"></el-table-column>
        <el-table-column
          label="创建时间"
          prop="create_time"
          width="160px"
        ></el-table-column>
        <el-table-column label="类型">
          <template slot-scope="{ row }">
            <p>{{ getType(row) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="{ row }">
            <p>{{ getStatusLabel(row) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="{ row }">
            <div class="flex flex-wrap">
              <el-button
                type="primary"
                @click="edit(row)"
                size="small"
                v-if="row.status < 5"
                >编辑</el-button
              >
              <el-button
                type="primary"
                @click="details(row)"
                size="small"
                v-if="row.status >= 5"
                >查看</el-button
              >
              <el-button
                type="warning"
                @click="openCoach(row)"
                size="small"
                v-if="row.type == 'order' && row.coach_id == 0"
                >派单</el-button
              >
              <el-button
                type="info"
                @click="process(row)"
                size="small"
                v-if="row.status == 0"
                >审核</el-button
              >
              <el-button
                type="success"
                v-if="row.status == 1 || row.status == 4"
                @click="update(row, 3)"
                size="small"
                >上架</el-button
              >
              <el-button
                type="danger"
                v-if="row.status == 3"
                @click="update(row, 4)"
                size="small"
                >下架</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="Total"
      ></paging-fy>
    </el-row>

    <el-dialog title="选择教练" :visible.sync="sendShow" width="50%">
      <div class="flex al-c">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="vip">会员教练</el-checkbox>
          <el-checkbox label="sign">特约教练</el-checkbox>
          <el-checkbox label="cate">同类目教练</el-checkbox>
        </el-checkbox-group>
        <el-input
          class="m-l20"
          placeholder="请输入教练姓名或电话"
          v-model="keyword"
        >
        </el-input>
        <el-button type="primary" @click="searchCoach(1)" icon="el-icon-search"
          >搜索</el-button
        >
      </div>

      <auto-table
        :DataList="coachList"
        :option="coachOption"
        :total="coachTotal"
        @changePage="searchCoach"
      >
        <template #avatar="{ row }">
          <img
            :src="row.head_portrait"
            alt=""
            style="width: 60px; height: 60px"
          />
        </template>
        <template #name="{ row }">
          {{ row.name }}
        </template>
        <template #type="{ row }">
          {{ getCoachType(row) }}
        </template>
        <template #handle="{ row }">
          <el-button size="small" type="primary" @click="handlerOrder(row)"
            >确定</el-button
          >
        </template>
      </auto-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<script>
import citySelect from "../../components/select/citySelect.vue";
import mechanismSelect from "../../components/select/mechanismSelect.vue";
import userSelect from "../../components/select/userSelect.vue";
export default {
  components: {
    mechanismSelect,
    userSelect,
    citySelect,
  },
  data() {
    return {
      nav: { firstNav: "需求课程管理中心", secondNav: "需求课程管理" },
      DataList: [],
      is_loading: true,
      currentPage: 1,
      Total: 0,
      status: "",
      categories: "",
      create_type: "",
      title:'',
      options: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "通过",
        },
        {
          value: 2,
          label: "拒绝",
        },
        {
          value: 3,
          label: "上架中",
        },
        {
          value: 4,
          label: "已下架",
        },
        {
          value: 5,
          label: "接单中",
        },
        {
          value: 6,
          label: "已接单",
        },
        {
          value: 7,
          label: "上课中",
        },
        {
          value: 8,
          label: "已完成",
        },
        {
          value: 11,
          label: "已取消",
        },
        {
          value: 12,
          label: "售后中",
        },
      ],
      user_id: "",
      mechanism_id: "",
      price: "",
      listPageChild: [],
      type: "need",
      cityoptions: JSON.parse(localStorage.getItem("addrList")),
      cityProps: {
        checkStrictly: true,
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      cityParam: {},
      keyword: "",
      sendShow: false,
      coachOption: [
        { name: "头像", value: "avatar", type: "custom" },
        { name: "姓名", value: "name", type: "custom" },
        { name: "电话", value: "phone", width: "120px" },
        { name: "身份", value: "type", type: "custom" },
        { name: "类目", value: "teaching_categories" },
        { name: "操作", value: "handle", type: "custom", width: "200px" },
      ],
      coachTotal: 0,
      coachList: [],
      currentNeed: {},
      checkList: ["cate"],
    };
  },
  mounted() {
    this.getList();
    this.GetListPageChild();
  },
  methods: {
    getCoachType(row){
        if(row.coach_role === 'sign'){
          return '特约教练'
        }
         if(row.identity_type === 2){
          return '会员教练'
        }
         return '普通教练'
      
    },
    handlerOrder(row) {
      if (row.id == this.currentNeed.coach_id) {
        this.$message("不能选择原教练");
        return;
      }

      this.$confirm(`确认将订单派给 ${row.real_name}教练`).then((res) => {
        this.$axios
          .post('/user/userCourseNeed/systemSendOrderCoach', {
              status:6,
              id:this.currentNeed.id,
              coach_id: row.id
          })
          .then((res) => {
             this.$message({ message: "派单成功" });
              this.getList();
              this.sendShow = false
          }).catch(err=>{
            this.$message({ message: err.data.message });
          })
      });
    },
    searchCoach(p) {
      let page = p || 1;
      const numberReg = /^\d/;
      let data = {
        currentPage: page,
        status: 2,
        city: this.currentNeed.city,
      };
      if (this.checkList.includes("cate")) {
        data.teaching_categories = this.currentNeed.categories;
      }
      if (this.checkList.includes("sign")) {
        data.coach_role = "sign";
      }
      if (this.checkList.includes("vip")) {
        data.identity_type = 2;
      }
      numberReg.test(this.keyword)
        ? (data.phone = this.keyword)
        : (data.real_name = this.keyword);
      let url = "/user/coach/queryManagerListPage";
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.coachList = res.data.data.rows;
          this.coachTotal = res.data.data.total;
        });
    },
    openCoach(v) {
      this.currentNeed = v;
      this.sendShow = true;
    },
    cityChange(v) {
      console.log(v);
      this.cityParam.province = v[0] || null;
      this.cityParam.city = v[1] || null;
      this.cityParam.district = v[2] || null;
    },
    getType(row) {
      if (row.type == "course") {
        return "课包";
      }
      if (row.type == "need") {
        return row.create_type === 1 ? "官方需求" : "需求";
      }
      if (row.type == "order") {
        return row.create_type === 1 ? "官方订单" : "订单";
      }
    },
    getStatusLabel(row) {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].value === row.status) {
          return this.options[i].label;
        }
      }
      return row.status;
    },

    GetListPageChild() {
      this.$axios({
        url: "/user/mechanismCategory/queryListPageChild",
        params: {
          type: 2,
          status: 2,
          source: "课程",
        },
      }).then((res) => {
        this.listPageChild = res.data.data;
      });
    },
    // 发布需求
    addCourse() {
      this.$router.push({ name: "generateCourse" });
    },
    process(row) {
      this.$router.push({
        name: "generateCourse",
        query: { id: row.id, type: "process" },
      });
    },
    details(row) {
      this.$router.push({ name: "courseNeedDetails", query: { id: row.id } });
    },
    /**编辑 */
    edit(row) {
      this.$router.push({ name: "generateCourse", query: { id: row.id } });
    },
    //更新状态
    update(row, status) {
      let url = "/user/userCourseNeed/update";
      let data = {
        id: row.id,
        status,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "成功" });
          this.getList();
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    //需求列表
    getList() {
      this.is_loading = true;
      let url = "/user/userCourseNeed/queryManagerListPage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        categories: this.categories || null,
        status: this.status,
        create_type: this.create_type || null,
        type: this.type || null,
        title:this.title || null,
        ...this.cityParam,
      };
      if (this.cityList.length > 0) {
        let len = this.cityList.length;
        switch (len) {
          case 1:
            params.province = this.cityList[len - 1];
            break;
          case 2:
            params.city = this.cityList[len - 1];
            break;
          case 3:
            params.district = this.cityList[len - 1];
            break;
          default:
            break;
        }
      }

      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.DataList = res.data.data.rows;
          this.Total = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getList();
    },
  },
};
</script>
    
<style lang="less" >
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;

  .el-form-item {
    width: 40%;
  }
}

.el-button + .el-button {
  margin-left: 0;
}
</style>
    